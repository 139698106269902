.esg-framework-banner {
  background-image: url("../../img/esgbg.jpeg");
  background-position: 50% 70%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  position: relative;
  .overlay-bg-esg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #000000;
    opacity: 0.1;
  }
  .grey-bg-with-text {
    background-color: rgba(0, 0, 0, 0.6);
    height: 60vh;
    width: 50%;
    border-radius: 0 0 30px 0;
    color: white;
    @media screen and (max-width: 500px) {
      width: 80%;
    }
    .text-wrapper {
      width: 80%;
      margin: auto;
      padding-top: 60px;
    }
  }
}

.title-depth {
  text-align: center;
  padding: 40px;
}
.listing-wrapper {
  margin-top: 30px;
  margin-bottom: 60px;
  li {
    cursor: pointer;
  }
}
.esg-framework-banner-2 {
  background-image: url("../../img/esgframebg.jpeg");
  background-position: 70% 20%;
}

.img-for-gri {
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  img {
    width: 100%;
  }
}
