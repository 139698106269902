.contactus-second-wrapper {
  width: 100%;
  .contact-us-main-wrapper {
    background-image: url("../../../public/assets/2022/09/dot_pattern.png");
    background-repeat: repeat;
    background-size: auto;
    height: 50vh;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      height: 20vh;
    }
    .contact-us-container {
      width: 85%;
      margin: auto;
      gap: 10px;
    }
  }
  .contact-us-address-form {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    .form-section {
      border-radius: 10px;
      border: 1px solid #dedede;
      width: 50%;
      padding: 50px;
      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 20px;
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .text-feild {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
    .address-section {
      width: 50%;
      padding: 50px;
      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 20px;
      }
      .addresses {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
