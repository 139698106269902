.expertise-wrapper {
  width: 100%;

  //   banner
  .banner-wrapper {
    width: 85%;
    margin: auto;

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    .exper-title {
      text-align: center;

      @media screen and (max-width: 991px) {
        font-size: 35px;
        line-height: 1.2;
        padding: 40px 20px;
      }
    }

    .images-wrapper {
      display: flex;
      position: relative;
      height: 90vh;
      width: 100%;
      margin-top: 50px;

      @media screen and (max-width: 900px) {
        height: 55vh;
      }

      .circle-img {
        position: absolute;
        z-index: -1;
        left: 15%;
        top: 5%;
        width: 40%;

        @media screen and (max-width: 765px) {
          top: 8%;
        }

        img {
          width: 100%;
        }
      }

      .android-img {
        width: 25%;
        position: absolute;
        z-index: 1;
        left: 35%;

        @media screen and (max-width: 765px) {
          top: 5%;
        }

        img {
          width: 100%;
        }
      }

      .credit-img {
        width: 25%;
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 10%;

        img {
          width: 100%;
        }
      }
    }
  }

  //   hightlight
  .hightlight-wrapper {
    background-color: #fff9f4;
    margin: auto;
    padding: 40px;

    @media screen and (max-width: 900px) {
      padding: 20px;
    }

    .text-section {
      display: flex;
      justify-content: space-around;
      padding: 40px;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        padding: 20px;
      }
    }

    .highlight-section {
      display: flex;
      justify-content: space-between;
      margin: auto;
      width: 85%;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        width: 100%;
      }

      .highlight-img-wrapper {
        width: 60%;
        display: flex;
        align-items: center;
        padding: 40px;
        border-radius: 20px;
        background-color: white;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        @media screen and (max-width: 900px) {
          flex-direction: column;
          width: 100%;
        }

        .highlight-img {
          display: flex;
          align-items: center;

          .collage {
            width: 300px;
            height: 300px;
            margin-left: -100px;

            @media screen and (max-width: 900px) {
              width: 100%;
              height: 100%;
              margin-left: 0;
            }
          }

          .smcollage {
            width: 100%;
            margin-top: 150px;
          }
        }

        .text-of-highlight {
          padding: 20px;
          width: 50%;

          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }

        .button-interested {
          width: 200px;
          font-size: 20px;
          font-weight: 700;
          padding: 20px;
          border-radius: 30px;
        }
      }

      .highlights {
        width: 35%;
        display: flex;
        gap: 20px;
        padding: 20px;
        flex-direction: column;
        background-color: white;
        border-radius: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        div {
          margin: auto;
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  //   key components
  .ksg-comp-wrapper {
    background-color: #ffffff;
    margin: auto;
    padding: 40px;

    @media screen and (max-width: 900px) {
      padding: 20px;
    }

    .circle-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      .circle1 {
        border-radius: 50%;
        border: 1px solid #999999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 300px;
        width: 300px;
        padding: 50px;
        text-align: center;
        background-color: #ffffff;

        svg {
          width: 40px;
          height: 40px;
        }

        .divider-1 {
          width: 60%;
          height: 5px;
          background-color: #d8d8d8;
        }

        .divider-2 {
          width: 50%;
          height: 5px;
          background-color: #d8d8d8;
        }
      }

      .circle2 {
        border-radius: 50%;
        border: none;
        background-color: #efefef;
      }

      .circle3 {
        border-radius: 50%;
        border: none;
        background-color: #999999;
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      .circle4 {
        border-radius: 50%;
        border: none;
        background-color: #333333;
        color: #ffffff;
        padding: 20px;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .benjamin {
      text-align: center;
      color: #c87533;
    }
  }

  //   goals
  .goals-wrapper {
    margin: auto;
    padding: 40px;
    text-align: center;
    color: #000;

    .subtitle-text {
      width: 80%;
      margin: 40px auto;
    }

    .poster-img {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  //   commit
  .commit-wrapper {
    width: 100%;
    margin: auto;
    background-color: #fff9f4;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    .img-people {
      position: relative;
      height: 500px;
      width: 50%;

      .img-wrapper {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 95%;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .people1-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 36%;
        top: 45%;

        @media screen and (max-width: 991px) {
          top: 31%
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;

          @media screen and (max-width: 991px) {
            width: 115px;
            height: 115px;
            border-radius: 50%;
          }
        }
      }

      .people2-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 70%;
        top: 25%;

        @media screen and (max-width: 991px) {
          top: 12%
        }

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }

      .people3-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 80%;
        top: 65%;

        @media screen and (max-width: 991px) {
          top: 41%
        }

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }

      .people4-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        top: 80%;

        @media screen and (max-width: 991px) {
          left: 5%;
          top: 47%
        }

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }

      .people5-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 60%;
        top: 95%;

        @media screen and (max-width: 991px) {
          left: 50%;
          top: 65%
        }

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }

      .people6-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        top: 15%;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }

      }
    }

    .we-are-commited {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 50%;
      padding: 100px;
      z-index: 1;

      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 20px;
      }

      .star {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        gap: 10px;

        .star-svg {
          width: 30px;
          height: 30px;
        }

        svg {
          path {
            fill: #fcc400;
          }
        }
      }

      .desc {
        width: 70%;

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}