.people-bg {
  background-image: url("../../img/Peoplebg.jpeg");
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;

  .overlay-people {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  .meet-text {
    width: 60%;
    padding: 30px;
    margin: auto;
    text-align: center;
    color: white;
    h1{
      @media screen and (max-width: 900px) {
        font-size: 30px;
        padding-bottom: 10px;
      }
    }
    p{
    font-size: 21px;
    line-height: 1.5;
    @media screen and (max-width: 900px) {
    
      text-align: justify;

    }
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 120px 20px;
    }
  }
}

.people-wrapper {
  width: 90%;
  margin: auto;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 900px) {
    width: 100%;

    padding: 20px;
  }
  .people-card {
    width: 350px;
    margin-bottom: 40px;
    .people-img {
      border-radius: 20px;
      transition: transform 1s;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &:hover {
        transform: scale(0.93);
      }
    }
  }
}
