@import "../index.scss";

@font-face {
  font-family: "CircularStdlight";
  src: url("../Assets/Fonts/CircularStd/CircularStd-Book.otf");
  format: ("otf");
}
$circular-std: "CircularStdlight", sans-serif;

.font-circular-md {
  font-family: $circular-std;
  color: #000;
  font-weight: 500;
  font-weight: 16px;
}
a {
  text-decoration: none;
  color: black;
}
// graph1
.grey-bg-graph1 {
  background-color: #f2f2f2;
}
.follow-us {
  display: flex;
  gap: 25px;
  margin-top: 20px;
  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.graph-img {
  img {
    width: 100%;
  }
}
.text-grey {
  color: #9b9b9b;
}
.text-green {
  color: #3bac75;
}
.text-black {
  color: #000;
}
.text-orange {
  color: #c87533;
}

.divider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .vertical-divider {
    transform: rotate(90deg);
    width: 100px;
    background-color: #000000;
  }
}
.css-1wc0u5o-MuiPaper-root-MuiAccordion-root:before {
  background: none !important;
}
.Accordian-wrapper {
  background-color: #ffffff;
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  background-color: white;
  .Accordian-solo {
    padding: 10px;
    outline: none !important;
    border: none !important;
    .header {
      border-radius: 10px;
      svg {
        color: #000000;
        width: 25px;
        height: 25px;
      }
    }
    .header-active {
      border-radius: 10px;
      color: #ffffff;
      background-color: #3bac75;
      svg {
        color: #ffffff;
        width: 25px;
        height: 25px;
      }
    }
    .Desc {
      padding: 10px;
    }
  }
}

.try-esg-wrapper {
  width: 85%;
  background: radial-gradient(at bottom center, #3bac75 20%, #040b10 100%);
  border-radius: 15px;
  margin: auto;
  position: relative;
  overflow: hidden;
  padding: 30px;
  display: flex;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 20px;
  }
  img {
    position: absolute;
    border-radius: 15px;
    width: 60%;
    object-fit: cover;
    bottom: -10%;
    right: -10%;
    height: 90%;
    @media screen and (max-width: 800px) {
      width: 100%;
      margin-top: 100px;
      height: 60%;
    }
  }

  .text-section {
    display: flex;
    position: relative;
    color: #ffffff;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    padding: 50px;
    @media screen and (max-width: 800px) {
      width: 100%;
      padding: 20px;
      margin-bottom: 50%;
    }
  }
}

// pricing
.pricing-main-wrapper {
  width: 85%;
  margin: auto;
  flex-direction: column;
  display: flex;
  gap: 40px;

  .info-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .subtitle-text {
      line-height: 40px;
      svg {
        path {
          fill: #3bac75;
        }
      }
    }
    .first-div {
      width: 48%;
      padding: 40px;
      display: flex;
      flex-direction: column;
      border: 1px solid transparent;
      &:hover {
        background-color: white;
        border: 1px solid #3bac75;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        transform: scale(1.01);
        -webkit-transition: all 1s ease;
        transition: all 1s ease;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .second-div {
      width: 48%;
      padding: 40px;
      display: flex;
      flex-direction: column;
      background-color: white;
      border: 1px solid #3bac75;
      border-radius: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      &:hover {
        transform: scale(1.01);
        -webkit-transition: all 1s ease;
        transition: all 1s ease;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

// common grid
.grid-container {
  width: 85%;
  margin: auto;
  padding: 40px;
  @media screen and (max-width: 900px) {
    width: 95%;
    padding: 20px;
  }
}

// buttons
.common-button {
  border: 1px solid #3bac75;
  border-radius: 20px;
  width: 100%;
  padding: 10px 30px;
  font-size: 15px;
  color: #3bac75;
  transition: transform 0.2s;
  background-color: transparent;
  a{
      color: #3bac75;
  }
  &:hover {
    background-color: #3bac75;
    color: #ffffff;
    outline: none;
    transform: scale(1.05);
    a{
          color: #ffffff;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 80px;
    padding: 5px;
  }
}
.common-button-opposite {
  border-radius: 30px;
  width: 200px;
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  font-size: 20px;
  color: #ffffff;
  transition: transform 0.2s;
  background-color: #3bac75;
  border: 1px solid #3bac75;
  &:hover {
    background-color: transparent;
    color: #3bac75;
    outline: none;
    transform: scale(1.05);
  }
}
.orange-button {
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  transition: transform 0.2s;
  background-color: #c87533;
  border: 1px solid #c87533;
  &:hover {
    background-color: transparent;
    border: 1px solid #ffffff;
    transform: scale(1.05);
  }
}

.button-width {
  position: relative;
  z-index: 1;
  padding: 20px 40px;
  width: 200px;
  border-radius: 30px;
  font-size: 18px;
}

.index-wrapper {
  margin-top: 5%;
}
.legal-bg {
  background-image: url("../img/legalbg.jpg");
  height: 80vh;
  margin-top: 5%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  .overlay-legal {
    position: absolute;
    top: 0;
    background-color: #000000;
    opacity: 0.3;
    height: 100%;
    width: 100%;
  }
}
.card-legal {
  width: 350px;

  margin-top: 30px;
  margin-bottom: 30px;
  background: white;
  padding: 30px;
  border-radius: 5px;
}
.error-wrapper {
  width: 70%;
  min-height: 70vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .input-box {
    width: 80%;
  }
  .search-btn {
    background-color: #3bac75;
    border: 1px solid #3bac75;
    height: 55px;
    width: 20%;
    color: white;
    &:hover {
      background-color: transparent;
      color: #3bac75;
    }
  }
}
