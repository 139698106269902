.pricing-wrapper {
  width: 100%;
  .pricing-main-wrapper {
    width: 85%;
    margin: auto;
    flex-direction: column;
    display: flex;
    gap: 40px;

    .info-div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      .subtitle-text {
        line-height: 40px;
        svg {
          path {
            fill: #3bac75;
          }
        }
      }
      .first-div {
        width: 48%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        &:hover {
          background-color: white;
          border: 1px solid #3bac75;
          border-radius: 20px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
          transform: scale(1.01);
          -webkit-transition: all 1s ease;
          transition: all 1s ease;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
      .second-div {
        width: 48%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px solid #3bac75;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        &:hover {
          transform: scale(1.01);
          -webkit-transition: all 1s ease;
          transition: all 1s ease;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
    }
    .Accordian-section {
      width: 65%;
      margin: auto;
    }
  }
}

.title {
  text-align: center;
  padding: 40px;
  color: #000000;
}
