// planet programme
// banner
.planet-banner-bg {
  background-image: url("../../img/planetprogbg.jpeg");
  height: 50vh;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

// patrons
.patrons-box {
  text-align: center;
  img {
    height: 180px;
  }
}

.text-align-center {
  text-align: center;
}

// almanac
.almanac-banner {
  background-image: url("../../img/Almanacbg.jpeg");
  height: 50vh;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

.global-india-img {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: box-shadow 1s;
  margin-bottom: 20px;
  img {
    width: 70%;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

// podcast
.podcast-banner {
  background-image: url("../../img/mikebg.jpeg");
  height: 50vh;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

.img-section-podcast {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rl1-banner {
  background-image: url("https://esgfit.demoserver.co.in/wp-content/uploads/2022/10/Header-Image-1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.stat-bg-wrapper {
  background-image: url("https://esgfit.demoserver.co.in/wp-content/uploads/2022/10/sign1.jpeg");
  // min-height: 70vh;
  display: flex;
  justify-content: center;
  .stat-details {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    gap: 20px;
  }
}
.podcast-details-wrapper {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 15px;
  .title-bar {
    background-color: #242424;
    border-radius: 15px;
    margin: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    img {
      width: 120px;
      height: 120px;
      border-radius: 8px;
      @media screen and (max-width: 991px) {
        width: 64px;
        height: 64px;
      }
    }
  }
}
.subtitle-area {
  display: flex;
  margin-top: 10px;
}
.preview-text {
  font-size: 10px;
  background-color: hsla(0, 0%, 100%, 0.7);
  color: #121212;
  margin-right: 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-name {
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.7);
  font-weight: 700;
  margin-inline-end: 8px;
}
.text-title {
  overflow: hidden;
  white-space: nowrap;
  color: white;
  margin: 10px;
}

.icon-div {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: flex-end;
  flex-direction: column;
  color: white;
  svg {
    width: 20px;
    height: 20px;
  }
}

.people-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .details-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}

.text-bottom {
  margin: 20px;
  .subdetails {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: #666666;
  }
}
.social-media-icon {
  display: flex;
  gap: 10px;
  .icon-wrapper {
    color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .facebook {
    background: #3b5998;
  }
  .twitter {
    background: #00acee;
  }
  .linkdin {
    background: #0a66c2;
  }
  .whatsapp {
    background: #25d366;
  }
}
