.footer-main-wrapper {
  background-image: url("../../img/Footer/Footerbg.jpg");
  background-position: 10% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 30px 10px;
  .divider {
    background-color: #d8d8d8;
    width: 85%;
    height: 1px;
    margin: 0 auto 30px;
  }
  .footer-wrapper {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer-link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px;
      a {
        color: #000;
        font-size: 15px;
        font-weight: 400;
      }
      .google-map {
        img {
          width: 20px;
          height: 25px;
        }
      }
    }
    .img-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(223, 223, 223, 0.8);
    opacity: 0.4;
    z-index: -1;
  }
}
.logo-section {
  width: 85%;
  margin: auto;
  padding: 30px;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 10px;
  }
  img {
    width: 300px;
  }
}
