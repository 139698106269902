@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600&display=swap");

* body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Karla";
  src: url("./Assets/Fonts/Karla.ttf");
  format: ("ttf");
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("./Assets/Fonts/CircularStd/CircularStd-Bold.otf");
  format: ("otf");
  font-display: swap;
}
@font-face {
  font-family: "CircularStdMd";
  src: url("./Assets/Fonts/CircularStd/CircularStd-Medium.otf");
  format: ("otf");
}

.App {
  overflow: hidden;
}

[data-aos="bounce-around"] {
  transform: skewX(45deg);
  opacity: 0;
  transition-property: transform, opacity;
  
&.aos-animate {
    transform: skewX(0);
    opacity: 1;
  }
}