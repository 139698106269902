.Navbar {
  background: #ffff;
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  margin: auto;

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
  }
  a {
    font-size: 16px;
    font-weight: 500;
  }
  .search-div {
    display: flex;
    justify-content: center;
    margin: auto 10px;
    svg {
      path {
        fill: #3bac75;
      }
    }
  }
  .logo {
    width: 180px;
    @media screen and (max-width: 1200px) {
      width: 150px;
    }
  }
  .dropdowns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px auto;
    flex-grow: 1;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .button-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px auto;
    flex-grow: 1;
    @media screen and (max-width: 1200px) {
      justify-content: space-between;
      flex-grow: 0;
      margin: 20px;
    }
  }
  .menutitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    svg {
      stroke: #000;
      fill: #000;
      margin-right: 10px;
    }
  }
}

// menu

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  .css-s65e4h-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    min-width: 220px;
    border-radius: 5px;
    .css-6hp17o-MuiList-root-MuiMenu-list {
      .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        font-size: 14px;
        padding: 10px;
      }
    }
  }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  .css-6hp17o-MuiList-root-MuiMenu-list {
    min-width: 220px;
    border-radius: 5px;
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      font-size: 14px;
      padding: 10px;
    }
  }
}
.mobile-responsive {
  display: none;
}

@media screen and (max-width: 991px) {
  .mobile-responsive {
    display: flex;
    justify-content: center;
  }
}

.signup {
  margin: auto 10px;
  @media screen and (max-width: 500px) {
  }
}
