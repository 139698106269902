.about-objective {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.colored-loader {
  img {
    width: 80px;
    margin-top: 40px;
  }
}

.kpartners-wrapper {
  width: 85%;
  margin: auto;
  padding: 50px;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 20px;
  }
}
.techimg {
  img {
    width: 100%;
  }
}
.grey-bg {
  width: 80%;
  background-color: #efefef;
  border-radius: 20px;
  padding: 40px;
  height: 600px;
  position: relative;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 20px;
    height: 500px;
  }
  .ddimg {
    position: absolute;
    right: -20%;
    bottom: -10%;
    img {
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      height: 200px;
      width: 300px;
    }
  }
}
.globeimg {
  img {
    width: 100%;
    margin-top: 40px;
  }
}
.pink-bg {
  background-color: #fff9f4;
  width: 100%;
}

.app-1 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 20%;
  img {
    width: 40px;
  }
}

.app-2 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 25%;
  top: 10%;
  img {
    width: 40px;
  }
}
.app-3 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 70%;
  top: 5%;
  img {
    width: 40px;
  }
}
.app-4 {
  position: absolute;
  right: 0;
  top: 20%;
  img {
    width: 40px;
  }
}
