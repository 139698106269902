$height: 16px;
$halfheight: ($height/2);
$thickness: 110%; // increase to make the line thicker
$offset: 4px;
$backgroundcolor: #ffffff;
$linecolor: #c87533;

.home-wrapper {
  width: 100%;

  //   home banner
  .homebanner-wrapper {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;



    .home-text-container {
      background-image: linear-gradient(to bottom right, #3bac75, #c87533);
      clip-path: polygon(50% 100%, 100% 50%, 100% 0, 0 0, 0 50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-top: 50px;
      height: 107vh;
      color: white;

      @media screen and (max-width: 991px) {
        clip-path: none;
        height: 100%;
        padding-bottom: 15px;
      }

      .enahance-text {
        width: 60%;
        margin: 20px auto;

        @media screen and (max-width: 991px) {
          width: 90%;
        }
      }

      .desc-text {
        width: 40%;
        margin: 10px auto;
        color: white;

        @media screen and (max-width: 991px) {
          width: 70%;
        }
      }

      .white-loader {
        padding: 30px;

        img {
          width: 90px;
          height: 90px;
        }
      }
    }

    .homeIllus {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  //   service
  .service-anthem-section {
    .title-section {
      width: 85%;
      margin: auto;
      padding: 30px;
      text-align: center;

      .anthem-text {
        color: #c87533;
        margin: 30px;
      }
    }

    .card-section-service {
      background-color: #3bac75;
      transition: border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px 0px 50px 0px;

      .card-wrapper-service {
        width: 90%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 991px) {
          width: 95%;
        }

        .card1 {
          width: 320px;
          height: auto;
          background: white;
          border-radius: 20px;
          padding: 30px;
          margin-top: 30px;
          box-shadow: 0px 2px 60px 0px rgb(0 0 0 / 10%);

          @media screen and (max-width: 991px) {
            margin-left: 0;
            width: 100%;
          }
        }

        .card2 {
          width: 250px;
          margin-top: 30px;
          background: white;
          border-radius: 20px;
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          box-shadow: 0px 2px 60px 0px rgb(0 0 0 / 10%);
 @media screen and (max-width: 991px) {
  width: 90%;
   margin-inline:auto
 }
          img {
            width: 80px;
            height: 60px;
          }

          .zigzag {
            .zig-zag-bottom {
              width: 50px;
              margin-bottom: 20px;
            }
          }
        }

        .zigzag {
          .zig-zag-bottom {
            width: 100px;
            margin-bottom: 60px;
            background: $backgroundcolor;
            position: relative;
            height: $height;
            z-index: 1;

            &:before,
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
            }

            &:before {
              height: ($height - $offset);
              top: $thickness;
              background: linear-gradient(-135deg,
                  $linecolor $halfheight,
                  transparent 0) 0 $halfheight,
                linear-gradient(135deg, $linecolor $halfheight, transparent 0) 0 $halfheight;
              background-position: top left;
              background-repeat: repeat-x;
              background-size: $height $height;
            }

            &:after {
              height: $height;
              top: 100%;
              background: linear-gradient(-135deg,
                  $backgroundcolor $halfheight,
                  transparent 0) 0 $halfheight,
                linear-gradient(135deg,
                  $backgroundcolor $halfheight,
                  transparent 0) 0 $halfheight;
              background-position: top left;
              background-repeat: repeat-x;
              background-size: $height $height;
            }
          }
        }
      }
    }
  }

  //   our vision
  .our-vision {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: 30px;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    .left-section {
      width: 50%;

      .book-session {
        color: #3bac75;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }

    .right-section {
      width: 50%;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  // lumen
  .lumen-wrapper {
    width: 85%;
    padding: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    .img-section {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      margin-top: -50px;
      position: relative;

      .bg-img {
        position: absolute;
        bottom: 0;
        width: 55%;
        animation-name: floating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        z-index: -1;
      }

      .img-box {
        margin: 30px;

        img {
          width: 250px;
          margin: 20px;
        }
      }
    }
  }

  // explore
  .explore-wrapper {
    width: 85%;
    padding: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    .faq-img-second {
      display: flex;
      flex-direction: row-reverse;
    }

    .faq-img {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 30px;
      margin-bottom: 30px;

      .img-box {
        width: 48%;

        @media screen and (max-width: 991px) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }

      .faq-box {
        width: 48%;
        text-align: left;

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }

    .divider-section-explore {
      margin-top: 30px;
    }
  }

  // common
  .cards-section {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 20px auto;
    flex-wrap: wrap;

    .card-wrapper {
      width: 250px;
      position: relative;
      margin-bottom: 20px;
      color: #ffffff;

      .card {
        box-sizing: border-box;
        border-radius: 2px;
        padding: 10px;
        width: 240px;
        position: absolute;
        bottom: 5px;
        height: 200px;
        transition: height 1s ease 0s;
        overflow: hidden;
        cursor: pointer;
        left: 50%;
        transform: translate(-50%, -0%);

        &:hover {
          box-sizing: border-box;
          border-radius: 2px;
          padding: 10px;
          width: 100%;
          position: absolute;
          bottom: 0;
          height: 90%;
          transition: height 1s ease 0s;
          background: linear-gradient(0deg, #3bac75, transparent 70%);
          border-radius: 20px;
        }
      }

      svg {
        width: 30px;
        height: 30px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
}